import * as React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Page from "../components/page-container";
import Header from "../components/page-header";
import Title from "../components/title";
import Blocks from "../components/content-blocks";
import { Query } from "../graphql-types";
import SEO from "../components/seo";

interface Props {
  data: Query;
}

export default function About({ data }: Props) {
  const [page] = data.allContentfulAboutPage.edges.map(e => e.node);
  return (
    <Layout>
      <SEO data={page.seo} />
      <Page>
        <Header>
          <Title>{page.title}</Title>
        </Header>
        <Blocks blocks={page.blocks} />
      </Page>
    </Layout>
  );
}

export const query = graphql`
  query About {
    allContentfulAboutPage {
      edges {
        node {
          id
          title
          seo {
            ...SEO
          }
          blocks {
            id
            size
            parallax
            color {
              value
            }
            image {
              fluid(maxWidth: 1400, quality: 80) {
                ...GatsbyContentfulFluid
              }
            }
            content {
              childMarkdownRemark {
                html
              }
            }
            titleColor {
              value
            }
            textColor {
              value
            }
          }
        }
      }
    }
  }
`;
